import { A11yModule } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { OverlayModule } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_CHIPS_DEFAULT_OPTIONS, MatChipsDefaultOptions, MatChipsModule } from '@angular/material/chips';
import {
    ErrorStateMatcher,
    MAT_DATE_FORMATS,
    MAT_RIPPLE_GLOBAL_OPTIONS,
    MatDateFormats,
    RippleGlobalOptions,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import {
    MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
    MatExpansionModule,
    MatExpansionPanelDefaultOptions,
} from '@angular/material/expansion';
import {
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatFormFieldDefaultOptions,
    MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioDefaultOptions, MatRadioModule } from '@angular/material/radio';
import { MAT_SELECT_CONFIG, MatSelectConfig, MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const EXPANSION_PANEL_HEIGHT = '48px';

const globalRippleConfig: RippleGlobalOptions = { disabled: true };
const globalFormFieldConfig: MatFormFieldDefaultOptions = { appearance: 'outline', floatLabel: 'always' };
const globalChipsConfig: MatChipsDefaultOptions = {
    separatorKeyCodes: [ENTER, COMMA],
    hideSingleSelectionIndicator: true,
};
const globalRadioConfig: MatRadioDefaultOptions = { color: 'primary' };
const globalCheckboxConfig: MatCheckboxDefaultOptions = { color: 'primary' };
const globalDateFormats: MatDateFormats = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
const globalExpansionPanelConfig: MatExpansionPanelDefaultOptions = {
    collapsedHeight: EXPANSION_PANEL_HEIGHT,
    expandedHeight: EXPANSION_PANEL_HEIGHT,
    hideToggle: true,
};

// Hides the check-mark added in select components
const globalMatSelectConfig: MatSelectConfig = {
    hideSingleSelectionIndicator: true,
};

@Injectable()
export class CustomErrorStateMatcher extends ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return form && form.submitted && !!control.errors;
    }
}

const MaterialModules = [
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatMomentDateModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatTableModule,
    OverlayModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatSidenavModule,
    A11yModule,
];

const MaterialProviders = [
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: globalRadioConfig },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: globalFormFieldConfig },
    { provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: globalChipsConfig },
    { provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: globalCheckboxConfig },
    { provide: MAT_DATE_FORMATS, useValue: globalDateFormats },
    { provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS, useValue: globalExpansionPanelConfig },
    { provide: MAT_SELECT_CONFIG, useValue: globalMatSelectConfig },
];

export { MaterialModules, MaterialProviders };
