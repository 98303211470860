/* eslint-disable camelcase */
import {
    LONG_YEAR_DATE_FORMAT,
    LONG_DATE_FORMAT,
    LONG_DATE_WITH_NO_YEAR_FORMAT,
    LONG_DATE_WITH_WEEKDAY_FORMAT,
    SHORT_DATE_FORMAT,
    SHORT_DATE_WITH_NO_YEAR_FORMAT,
    SHORT_DATE_SEPARATED_WITH_NO_YEAR_FORMAT,
    SHORT_DATE_WITH_WEEKDAY_FORMAT,
    SHORT_DATE_WITH_WEEKDAY_NO_YEAR_FORMAT,
    SHORT_DATE_WITH_SHORT_WEEKDAY_FORMAT,
} from '@app/constants';
import { Locale } from '@app/types/translatable.type';

export type DateFormat =
    | 'short'
    | 'shortWeekday'
    | 'abbreviatedWeekday'
    | 'shortNoYear'
    | 'shortWeekdayNoYear'
    | 'long'
    | 'longYearDate'
    | 'shortSeparatedNoYear'
    | 'shortOnlyMonth'
    | 'longWeekday'
    | 'longNoYear';

export const FORMATS: Record<DateFormat, Record<Locale, string>> = {
    // Short formats have shortened month names (February = Feb)
    short: SHORT_DATE_FORMAT,
    shortWeekday: SHORT_DATE_WITH_WEEKDAY_FORMAT,
    abbreviatedWeekday: SHORT_DATE_WITH_SHORT_WEEKDAY_FORMAT,
    shortNoYear: SHORT_DATE_WITH_NO_YEAR_FORMAT,
    shortSeparatedNoYear: SHORT_DATE_SEPARATED_WITH_NO_YEAR_FORMAT,
    shortWeekdayNoYear: SHORT_DATE_WITH_WEEKDAY_NO_YEAR_FORMAT,
    shortOnlyMonth: { en_CA: 'MMM', fr_CA: 'MMM' },
    long: LONG_DATE_FORMAT,
    longYearDate: LONG_YEAR_DATE_FORMAT,
    longWeekday: LONG_DATE_WITH_WEEKDAY_FORMAT,
    longNoYear: LONG_DATE_WITH_NO_YEAR_FORMAT,
};
