import { Locale } from '@app/types/translatable.type';

export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const LONG_YEAR_DATE_FORMAT: Record<Locale, string> = {
    en_CA: 'YYYY-MM-DD',
    fr_CA: 'DD-MM-YYYY',
};
export const SHORT_DATE_FORMAT: Record<Locale, string> = { en_CA: 'MMM D, YYYY', fr_CA: 'D MMM, YYYY' };
export const SHORT_DATE_WITH_WEEKDAY_FORMAT: Record<Locale, string> = {
    en_CA: 'dddd MMM D, YYYY',
    fr_CA: 'dddd D MMM YYYY',
};
export const SHORT_DATE_WITH_NO_YEAR_FORMAT: Record<Locale, string> = {
    en_CA: 'MMM D',
    fr_CA: 'D MMM',
};
export const SHORT_DATE_SEPARATED_WITH_NO_YEAR_FORMAT: Record<Locale, string> = {
    en_CA: 'MMM, D',
    fr_CA: 'D, MMM',
};
export const SHORT_DATE_WITH_WEEKDAY_NO_YEAR_FORMAT: Record<Locale, string> = {
    en_CA: 'dddd, MMM D',
    fr_CA: 'dddd D MMM',
};
export const LONG_DATE_FORMAT: Record<Locale, string> = { en_CA: 'MMMM D, YYYY', fr_CA: 'D MMMM, YYYY' };

export const SHORT_DATE_WITH_SHORT_WEEKDAY_FORMAT: Record<Locale, string> = {
    en_CA: 'ddd MMM D, YYYY',
    fr_CA: 'ddd D MMM, YYYY',
};

export const LONG_DATE_WITH_WEEKDAY_FORMAT: Record<Locale, string> = {
    en_CA: 'dddd MMMM D, YYYY',
    fr_CA: 'dddd D MMMM YYYY',
};

export const LONG_DATE_WITH_NO_YEAR_FORMAT: Record<Locale, string> = { en_CA: 'MMMM D', fr_CA: 'D MMMM' };

export const TIME_TRACKING_TIME_FORMAT: Record<Locale, string> = {
    en_CA: 'h:mm A',
    fr_CA: 'H:mm',
};
